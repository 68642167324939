import SideMenu from "../../components/sidemenu";
import {Link} from "react-router-dom";
import {Button, Form, Table} from "react-bootstrap";
import {Link45deg, PencilSquare, PlusSquareFill, Search} from "react-bootstrap-icons";
import endpoints from "../../utils/endpoints.json";
import {useEffect, useState} from "react";
import axios from "axios";
import useAuth from "../../utils/useAuth";

export default function Storage() {

    const [storage, setStorage] = useState([])
    const [addProductItemName, setAddProductItemName] = useState('')
    const [addProductAmount, setAddProductAmount] = useState(0)
    const [addProductSize, setAddProductSize] = useState('')
    const {authenticated, login, logout, token} = useAuth()

    const [addedMessage, setAddedMessage] = useState('')

    const sizeOrder = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];


    const updateData = () => {
        axios.get(endpoints.APICalls + '/admin/storage', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setStorage(resp.data)
            })
    }

    useEffect(() => {
        updateData()
    }, []);

    const ChangeProductStorageUnit = () => {
        axios.post(endpoints.APICalls + '/admin/storage/', {
            'iot': addProductItemName, 'size': addProductSize, 'amount': addProductAmount
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setAddedMessage(`Добавлено ${addProductAmount} единиц товара`)
                updateData()
            })
    }

    return (<div className={'container vw-100 pt-5'}>
        <h1>Склад</h1>
        <div className={'d-flex flex-column flex-lg-row'}>
            <SideMenu/>
            <div className={'flex-grow-1 flex-w'}>
                <div className={'alert alert-secondary'}>
                    <h3>Добавление товара</h3>
                    <Form.Group className="mb-3" controlId="formGroupTags">
                        <Form.Label>Название или артикул товара</Form.Label>
                        <Form.Control type="text" placeholder="Название или артикул товара"
                                      onInput={(e) => setAddProductItemName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupTags">
                        <Form.Label>Размер</Form.Label>
                        <Form.Control type="text" placeholder="Размер товара"
                                      onInput={(e) => setAddProductSize(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupTags">
                        <Form.Label>Кол-во</Form.Label>
                        <Form.Control type="number" placeholder="Кол-во"
                                      onInput={(e) => setAddProductAmount(e.target.value)}/>
                    </Form.Group>
                    <Button onClick={ChangeProductStorageUnit}>Добавить</Button>
                    <p>{addedMessage}</p>
                </div>
                <Table>
                    <thead>
                    <tr className={'fw-bold'}>
                        <td>Артикул</td>
                        <td>Наименование</td>
                        <td>Размеры</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(storage).map((item) =>
                            <tr>
                                <td>{item}</td>
                                <td>{storage[item].title}</td>
                                <td className={'d-flex gap-1'}>{
                                    storage[item].sizes.sort((a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)).map((size) => <div
                                        className={'p-1 border rounded'}>{size}</div>)
                                }</td>
                                <td><Link to={`${item}`}><Button variant={'secondary'}><Search/></Button></Link>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        </div>

    </div>)
}