import SideMenu from "../../../components/sidemenu";
import {Link} from "react-router-dom";
import {Badge, Button, Table} from "react-bootstrap";
import {Link45deg, PencilSquare, PlusSquareFill, Search, TicketDetailed} from "react-bootstrap-icons";
import endpoints from "../../../utils/endpoints.json";
import {useEffect, useState} from "react";
import axios from "axios";
import useAuth from "../../../utils/useAuth";

export default function SupportRequests() {

    const [requests, setRequests] = useState([])
    const {authenticated, login, logout, token} = useAuth()


    useEffect(() => {
        axios.get(endpoints.APICalls + '/admin/supportrequests', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setRequests(resp.data)
            })
    }, []);

    const sortRequests = (sortKey) => {
        let r = requests
        setRequests(null)
        axios.get(endpoints.APICalls + `/admin/supportrequests?sort=${sortKey}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setRequests(resp.data)
            })
    }


    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Запросы в службу поддержки</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 flex-w'}>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>ID</td>
                            <td>
                                <span onClick={() => sortRequests('date_inc')} role='button'><i
                                className='bx bx-up-arrow-alt'></i></span> Создано
                                <span onClick={() => sortRequests('date_desc')} role='button'><i
                                    className='bx bx-down-arrow-alt'></i></span>
                            </td>
                            <td>Тема</td>
                            <td>
                                <span onClick={() => sortRequests('status_inc')} role='button'><i
                                    className='bx bx-up-arrow-alt'></i></span>
                                Статус
                                <span onClick={() => sortRequests('status_desc')} role='button'><i
                                    className='bx bx-down-arrow-alt'></i></span>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            requests && (
                                requests.map((item) =>
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{new Date(item.date).toLocaleString('ru-ru')}</td>
                                        <td>{item.topic}</td>
                                        <td>
                                            {
                                                item.isActive ? (<Badge bg={'success'}>Активно</Badge>) : (
                                                    <Badge bg={'secondary'}>Закрыто</Badge>)
                                            }
                                        </td>
                                        <td>
                                            <Link to={'/dashboard/support/request/' + item.id}><Button
                                                variant={'secondary'}><Search/></Button></Link>
                                        </td>
                                    </tr>
                                )
                            )

                        }
                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}