import {Alert, Button, Form} from "react-bootstrap";
import data from "bootstrap/js/src/dom/data";
import {useEffect, useState} from "react";
import endpoints from '../utils/endpoints.json'
import axios from "axios";

export default function ProductForm({dataHolder, readyData = {}}) {

    const [ph, setPh] = useState([])

    const [cReadyData, setCReadyData] = useState({})

    // const addPhoto = () => {
    //     let e = document.querySelector('#photoloader')
    //
    //     dataHolder.photos = e.files
    //
    //     console.log(dataHolder)
    // }

    useEffect(() => {
        console.log("DEBUG READY DATA")
        console.log(readyData)
        setCReadyData(readyData)
    }, [readyData])

    useEffect(() => {

        console.log(ph)
    }, [ph]);

    const CheckFiltersAppearance = (rawString) => {
        axios.get(endpoints.APICalls + '/tags')
            .then((res) => {
                let resp = res.data
                let tags = []
                resp.forEach((tagInfo) => {
                    tags.push(tagInfo.name)
                })

                let productTags = rawString.split(',')
                let problemTags = []
                productTags.forEach((val, ind) => {
                    let trimed = val.trim().replaceAll(' ', '_')
                    console.log("Checking " + trimed)
                    console.log(tags)
                    if (!tags.includes(trimed) && trimed.length > 0) {
                        problemTags.push(trimed)
                    }
                })

                let filtersCheckerLabel = document.querySelector('#filtersChecker')
                filtersCheckerLabel.style.fontSize = "12px"

                console.log(problemTags)

                if (problemTags.length === 0) {
                    filtersCheckerLabel.style.color = "#64ff2b";
                    filtersCheckerLabel.innerHTML = "С фильтрами все в порядке <i class='bx bxs-check-circle'></i>"
                } else {
                    filtersCheckerLabel.style.color = "#ff3d2b";
                    filtersCheckerLabel.innerHTML = "Следующих фильтров не существует:<br/>"
                    problemTags.forEach((tag, ind) => {
                        filtersCheckerLabel.innerHTML += `<br /> ${ind + 1}. ${tag}`
                    })

                    filtersCheckerLabel.innerHTML += "<br /><br />Товар будет создан без этих тегов"
                }

            })
    }

    const MovePhoto = (dir, photoId) => {
        axios.post(endpoints.APICalls + '/admin/product/movephoto/', {
                "item": readyData.item,
                "photoId": photoId,
                "direction": dir
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`

                }
            }
        )
            .then((resp) => {
                axios.get(endpoints.APICalls + '/product/get?item=' + readyData.item)
                    .then((resp) => {
                        console.log(readyData.photos)
                        console.log(resp.data.data.photos)
                        setCReadyData(prevState => ({
                            ...prevState,
                            photos: resp.data.data.photos
                        }));
                    })
            })
    }

    return (
        <div>

            <Alert variant={'danger'}>Все поля обязательны к заполнению</Alert>

            <Form>
                <Form.Group className="mb-3" controlId="formGroupName">
                    <Form.Label>Наименование товара</Form.Label>
                    <Form.Control defaultValue={readyData.title} type="text" placeholder="Наименование"
                                  onChange={(e) => dataHolder.name = e.target.value}/>
                < /Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPrice">
                    <Form.Label>Цена</Form.Label>
                    <Form.Control defaultValue={readyData.price} type="number" placeholder="Цена"
                                  onChange={(e) => dataHolder.price = e.target.value}/>
                </Form.Group>
                {/*<Form.Group className="mb-3" controlId="formGroupShortDescription">*/}
                {/*    <Form.Label>Краткое описание</Form.Label>*/}
                {/*    <Form.Control defaultValue={readyData.shortDescription} type="text" placeholder="Краткое описание"*/}
                {/*                  onChange={(e) => dataHolder.shortDescription = e.target.value}/>*/}
                {/*</Form.Group>*/}
                <Form.Group className="mb-3" controlId="formGroupDescription">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control defaultValue={readyData.description} as={'textarea'} rows={3} placeholder="Описание"
                                  onChange={(e) => dataHolder.description = e.target.value}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupTags">
                    <Form.Label>Фильтры (через запятую)</Form.Label>
                    <Form.Control defaultValue={readyData.rowtags} type="text" placeholder="Теги"
                                  onChange={(e) => {
                                      CheckFiltersAppearance(e.target.value)
                                      dataHolder.tags = e.target.value
                                  }}/>
                    <p id={'filtersChecker'}></p>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupCover">
                    <Form.Label>Обложка</Form.Label>
                    <Form.Control id={'photoloader'} type="file" placeholder="Обложка" multiple
                                  onChange={(e) => {
                                      dataHolder.photos = e.target.files
                                  }}/>
                    {
                        cReadyData && (
                            cReadyData.photos && (
                                <div className={'mt-2'}>
                                    Текущие фотографии
                                    <div className={'d-flex gap-5 mt-2'} style={{width: 100}}>
                                        {
                                            cReadyData.photos.map((photo, index) =>
                                                <div>
                                                    <img style={{width: '175px'}} src={endpoints.MediaURL + photo.image}/>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <i className='bx bx-chevron-left' style={{fontSize: "24px"}}
                                                           onClick={() => MovePhoto('start', photo.id)}></i>
                                                        <i className='bx bx-chevron-right' style={{fontSize: "24px"}}
                                                           onClick={() => MovePhoto('end', photo.id)}></i>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )

                        )
                    }
                </Form.Group>

            </Form>
        </div>
    )
}