import SideMenu from "../../../components/sidemenu";
import {Badge, Button, Form, InputGroup, Table} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {Search, Send} from "react-bootstrap-icons";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {enableDismissTrigger} from "bootstrap/js/src/util/component-functions";
import endpoints from "../../../utils/endpoints.json"
import useAuth from "../../../utils/useAuth";
import InputField from "../../../components/inputField";

export default function SupportRequestDetails() {

    const {rid} = useParams()
    const [requestDetails, setRequestDetails] = useState(null)
    const [message, setMessage] = useState('')
    const messageInput = useRef()
    const supportAccountId = 2
    const {authenticated, login, logout, token} = useAuth()


    const UpdateRequestData = () => {
        axios.get(endpoints.APICalls + '/admin/supportrequests/details?id=' + rid, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setRequestDetails(null)
                setRequestDetails(resp.data)
            })
    }

    useEffect(() => {


        axios.get(endpoints.APICalls + '/admin/supportrequests/details?id=' + rid, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setRequestDetails(resp.data)

            })
        setInterval(() => {
            UpdateRequestData()
        }, 1000)


    }, []);

    const SendMessage = (msg) => {

        let m = ''
        switch (msg) {
            case null:
                m = message
                break;

            default:
                m = msg
                break;
        }

        console.log(m)
        axios.get(endpoints.APICalls + `/admin/supportrequests/sendmessage?cid=${requestDetails.chat.id}&message=${m}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                UpdateRequestData()
            })
    }

    useEffect(() => {

    }, [requestDetails])

    const CloseRequest = () => {
        axios.post(endpoints.APICalls + '/admin/supportrequests/close', {
            "cid": requestDetails.id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                UpdateRequestData()
            })
    }

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Подробности запроса в поддержку</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                    <SideMenu/>

                {
                    requestDetails && (
                        <div className={'d-flex flex-column gap-3 flex-w border'}>
                            <div className={'border rounded p-3'}>
                                <h2>Подробности обращения</h2>

                                <div className={'d-flex flex-column gap-0'}>
                                    <p className={'m-0'}><b>ID обращения:</b> {requestDetails.id}</p>
                                    <p className={'m-0'}><b>Статус:</b> {
                                        requestDetails.isActive ? (<Badge bg={'success'}>Активно</Badge>) : (
                                            <Badge bg={'secondary'}>Закрыто</Badge>)
                                    }</p>
                                    <p className={'m-0'}><b>Тема обращения:</b> {requestDetails.topic}</p>
                                    <p className={'m-0'}><b>Первичное описание проблемы:</b> {requestDetails.description}
                                    </p>
                                </div>
                                {
                                    requestDetails.isActive && (
                                        <Button variant={'danger'} onClick={CloseRequest}>Закрыть обращение</Button>
                                    )
                                }

                            </div>
                            <div className={'border rounded p-3'}>
                                <h2>Пользователь</h2>

                                <div className={'d-flex flex-column gap-0'}>
                                    <p className={'m-0'}><b>Имя:</b> {requestDetails.user.firstname}</p>
                                    <p className={'m-0'}><b>Телефон:</b> {requestDetails.user.mobilePhone}</p>
                                    <p className={'m-0'}><b>Почта:</b> {requestDetails.user.email}</p>
                                </div>
                            </div>
                            <div className={'border rounded p-3'}>
                                <h2>Чат</h2>
                                <div className={'d-flex flex-column gap-2 bg-info-subtle p-5 rounded'}>
                                    {
                                        Object.keys(requestDetails.chat.history).map((key) =>
                                            <div>
                                                <p className={'m-0'}>{requestDetails.chat.history[key].author !== requestDetails.chat.p1.id ? 'Служба поддержки в ' + key : 'Клиент в ' + new Date(key).toLocaleString('ru-ru')}</p>
                                                <div className={'d-flex'}>
                                                    <div
                                                        className={(requestDetails.chat.history[key].author === requestDetails.chat.p1.id ? 'bg-primary' : 'bg-secondary') + ' text-white p-2 rounded'}>
                                                        {requestDetails.chat.history[key].message}
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    }

                                </div>
                                {
                                    requestDetails.isActive && (
                                        <div className={'mt-5'}>
                                            <Form.Group className="mb-3" controlId="formGroupTags">
                                                <Form.Label>Сообщение клиенту</Form.Label>
                                                <InputField action={SendMessage} setValue={setMessage}/>
                                                <Button variant={'primary'} onClick={() => SendMessage(null)}
                                                        className={'mt-2'}>Отправить</Button>
                                            </Form.Group>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}