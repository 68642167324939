import {Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import SideMenu from "../components/sidemenu";

export default function MainAdmin() {
    return (
        <div className={'container d-flex vw-100 border'}>
            <SideMenu />
        </div>
    )
}