import {Form} from "react-bootstrap";
import {useEffect, useRef} from "react";

export default function InputField(props) {

    useEffect(() => {

    }, [])

    const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && event.shiftKey) {

      }
      else {
          if (event.key === 'Enter') {
              event.preventDefault()
                props.action(inputRef.current.value)
                props.setValue('')
                inputRef.current.value = ''
          }
      }
    };

    inputRef.current.addEventListener('keydown', handleKeyDown);

    return () => inputRef.current.removeEventListener('keydown', handleKeyDown);
  }, []);

    return (
        <Form.Control
            id={'inputField'}
            type="text"
            placeholder="Сообщение"
            as={'textarea'} rows={3}
            ref={inputRef}
            onChange={(e) => {
                props.setValue(e.target.value)
                console.log(e.target.value)
            }}
        />
    )
}