import SideMenu from "../../components/sidemenu";
import {Link, useParams} from "react-router-dom";
import {Button, Form, Table} from "react-bootstrap";
import {Link45deg, PencilSquare, PlusSquareFill} from "react-bootstrap-icons";
import endpoints from "../../utils/endpoints.json";
import {useEffect, useState} from "react";
import axios from "axios";
import useAuth from "../../utils/useAuth";

export default function StorageContainer() {

    const {item} = useParams()
    const sizeOrder = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];



    const {authenticated, login, logout, token} = useAuth()

    const [storage, setStorage] = useState(null)

    useEffect(() => {
        axios.get(endpoints.APICalls + `/admin/storage/details/?item=${item}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setStorage(resp.data)
            })
    }, [])

    const addStorageUnit = (size) => {
        axios.post(endpoints.APICalls + '/admin/storage/unit/adjust/', {
            "item": item,
            "size": size,
            "act": "add"
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                axios.get(endpoints.APICalls + `/admin/storage/details/?item=${item}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                })
                    .then((resp) => {
                        setStorage(resp.data)
                    })
            })
    }

    const removeStorageUnit = (size) => {
        axios.post(endpoints.APICalls + '/admin/storage/unit/adjust/', {
            "item": item,
            "size": size,
            "act": "remove"
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                axios.get(endpoints.APICalls + `/admin/storage/details/?item=${item}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                })
                    .then((resp) => {
                        setStorage(resp.data)
                    })
            })
    }

    const removeWholeStorageUnit = (size) => {
        axios.post(endpoints.APICalls + '/admin/storage/unit/remove/', {
            "item": item,
            "size": size,
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                axios.get(endpoints.APICalls + `/admin/storage/details/?item=${item}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                })
                    .then((resp) => {
                        setStorage(resp.data)
                    })
            })
    }

    return (<div className={'container vw-100 pt-5'}>
        <h1>Ячейка склада</h1>
        <div className={'d-flex flex-column flex-lg-row'}>
            <SideMenu/>
            <div className={'flex-grow-1 flex-w'}>
                {
                    storage && (
                        <>
                            <h2>{storage.title}</h2>
                            <h3>Имеющиеся размеры</h3>
                            <div className={'d-flex gap-5'}>

                                {
                                    Object.keys(storage.storage).sort((a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)).map((size) => <div className={'p-3 border rounded-2'}>
                                        <span className={'text-lg m-0'}><b>{size}</b></span>

                                        <div className={'d-flex justify-content-between align-items-center gap-2 mb-1'}>
                                            <i className='bx bxs-minus-circle' style={{cursor: "pointer"}}
                                               onClick={() => removeStorageUnit(size)}></i>
                                            <p className={'h2 m-0'}>{storage.storage[size]}</p>
                                            <i className='bx bxs-plus-circle' style={{cursor: "pointer"}}
                                               onClick={() => addStorageUnit(size)}></i>
                                        </div>
                                        <Button variant={'danger'} onClick={() => removeWholeStorageUnit(size)}>Удалить</Button>
                                    </div>)
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </div>

    </div>)
}