import SideMenu from "../../../components/sidemenu";
import {Alert, Button, Form, Table} from "react-bootstrap";
import {PencilSquare} from "react-bootstrap-icons";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import ProductForm from "../../../components/product_form";
import useAuth from "../../../utils/useAuth";

export default function EditProduct() {

    const newData = {
        name: null,
        price: null,
        shortDescription: null,
        description: null,
        tags: null,
        photos: []
    }

    const {item} = useParams()
    const [productData, setProductData] = useState('')
    const [done, setDone] = useState(false)
    const [updated, setUpdated] = useState(false)
    const {authenticated, login, logout, token} = useAuth()


    const updateProduct = () => {
        let data = new FormData()
        Object.keys(newData).forEach((key) => {
            if (newData[key] !== null) {
                data.append(key, newData[key])
                console.log(key)
                console.log(newData[key])
            }
        })

        newData['productId'] = item
        console.log("DEBUG PUTTING")
        console.log(newData)
        console.log(data)
        data.append('productItem', item)

        axios.put(endpoints.APICalls + '/admin/product/', newData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setDone(true)
                setUpdated(true)
            })
            .catch((err) => {
                setDone(true)
            })
    }

    useEffect(() => {
        axios.get(endpoints.APICalls + '/product/get?item=' + item, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setProductData(resp.data.data)
                newData.name = resp.data.data.title
                newData.price = resp.data.data.price
                newData.shortDescription = resp.data.data.shortDescription
                newData.description = resp.data.data.description
                newData.tags = resp.data.data.rowtags


                console.log(resp.data)
            })
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Редактирование товара</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>

                <div className={'flex-grow-1 flex-w'}>
                    {
                        done ? (
                            updated ? (
                                <Alert variant={'success'}>
                                    <p><b>Товар обновлен</b></p>
                                    <p>Ссылка на товар: <a target={'_blank'}
                                                           href={endpoints.ProductBaseLink + item}>{endpoints.ProductBaseLink + item}</a>
                                    </p>
                                </Alert>
                            ) : (
                                <Alert variant={'danger'}>
                                    <p><b>Ошибка</b></p>
                                    <p>Возникла ошибка при обновлении товара. Пожалуйста, обратитесь в службу
                                        поддержки.</p>
                                </Alert>
                            )
                        ) : (
                            <div>
                                <ProductForm dataHolder={newData} readyData={productData}/>
                                <Button variant={'success'} onClick={updateProduct}>Сохранить изменения</Button>
                            </div>

                        )
                    }

                </div>
            </div>

        </div>
    )
}