import {useState, useEffect} from 'react';
import axios from 'axios';
import endpoints from "./endpoints.json"

const useAuth = () => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            try {
                axios.post(endpoints.APICalls + '/token/refresh/', {
                    refresh: refreshToken
                })
                    .then((response) => {
                        localStorage.setItem('refresh_token', response.data.refresh);
                        localStorage.setItem('access_token', response.data.access);
                        setAuthenticated(true);
                    });

            } catch (error) {
                console.error(error);
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                setAuthenticated(false);
                alert(window.location)
                if (new URL(window.location).pathname !== '/') window.location = '/'
            }
        } else {
            setAuthenticated(false);
            if (new URL(window.location).pathname !== '/') window.location = '/'
        }
    }, []);

    const login = (username, password) => {
        try {
            axios.post(endpoints.APICalls + '/users/signin/', {
                username,
                password
            })
                .then((response) => {
                    const {refresh, access, isAdmin} = response.data;
                    if (isAdmin) {
                        localStorage.setItem('refresh_token', refresh);
                        localStorage.setItem('access_token', access);
                        setAuthenticated(true);
                        console.log("Authenticated")
                        window.location = '/dashboard/products'
                    }
                    else {
                        console.log('Failed to login')
                    }
                })
        } catch (error) {
            console.error(error);
        }
    };

    const token = () => {
        return localStorage.getItem('access_token')
    }

    const logout = () => {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        setAuthenticated(false);
    };

    return {
        authenticated,
        login,
        logout
    };
};

export default useAuth;