import {Suspense, useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import SideMenu from "../../../components/sidemenu";
import {Button, Table} from "react-bootstrap";
import {FolderSymlink, Link45deg, PencilSquare, PlusSquare, PlusSquareFill, Trash} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import useAuth from "../../../utils/useAuth";

export default function AdminProducts() {

    const [catalog, setCatalog] = useState([])
    const {authenticated, login, logout, token} = useAuth()


    useEffect(() => {
        axios.get(endpoints.APICalls + "/catalog?page=-1", {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                console.log(resp.data)
                setCatalog(resp.data)
            })
    }, []);

    const deleteProduct = (item) => {
        axios.delete(endpoints.APICalls + `/admin/product/?item=${item}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                axios.get(endpoints.APICalls + "/catalog", {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                })
                    .then((resp) => {
                        console.log(resp.data)
                        setCatalog(resp.data)
                    })
            })
    }

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Товары</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 flex-w'}>
                    <Link to={'/dashboard/products/create'}><Button><PlusSquareFill/> Создать</Button></Link>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>Артикул</td>
                            <td>Наименование</td>
                            <td>Цена</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            catalog.length > 0 && (
                                catalog.map((item) =>
                                    <tr>
                                        <td>{item.item}</td>
                                        <td>{item.title}</td>
                                        <td>{item.price}</td>
                                        <td className={'d-flex gap-1'}>
                                            <Link to={`/dashboard/products/edit/${item.item}`}><Button
                                                variant={'secondary'}><PencilSquare/></Button></Link>
                                            <Link to={endpoints.ProductBaseLink + item.item}><Button
                                                variant={'secondary'}><Link45deg/></Button></Link>
                                            <Button variant={'danger'}
                                                    onClick={() => deleteProduct(item.item)}><Trash/></Button>
                                        </td>
                                    </tr>
                                )
                            )

                        }
                        </tbody>
                    </Table>
                    {
                        catalog.length == 0 ? (
                            <div>
                                Загружаем каталог товаров
                            </div>
                        ) : (
                            <>{catalog.length}</>
                        )
                    }
                </div>
            </div>

        </div>
    )
}