import SideMenu from "../../../components/sidemenu";
import {Link, useParams} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import {FileMinusFill, FilePlusFill, PlusSquareFill} from "react-bootstrap-icons";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import useAuth from "../../../utils/useAuth";

export default function AddFilterToFiltersGroup() {

    const {gid} = useParams()
    const [groupData, setGroupData] = useState(null)
    const [tagsList, setTagList] = useState(null)
    const [gTagArr, setGTagArr] = useState([])
    const {authenticated, login, logout, token} = useAuth()

    let groupTagsArr = []

    const updateData = () => {
        axios.get(endpoints.APICalls + '/tags', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setTagList(null)
                setTagList(resp.data)
                axios.get(endpoints.APICalls + '/tags/groups?id=' + String(gid), {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    }
                })
                    .then((resp) => {
                        setGroupData(null)
                        groupTagsArr = []
                        setGroupData(resp.data)
                        Object.keys(resp.data[Object.keys(resp.data)[0]]).forEach((key) => {
                            groupTagsArr.push(resp.data[Object.keys(resp.data)[0]][key])
                        })
                        setGTagArr([])
                        setGTagArr(groupTagsArr)
                    })
            })
    }

    const switchTagInGroup = (tagId) => {
        axios.post(endpoints.APICalls + '/admin/tags/groups/switch', {
            'tagId': tagId,
            'groupId': gid
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                    updateData()
                }
            )
    }

    useEffect(() => {
        updateData()
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Редактирование группы фильтров</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 flex-w'}>
                    {
                        groupData && (
                            <h2>{Object.keys(groupData)[0]}</h2>
                        )
                    }
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>Фильтр</td>
                            <td>Находится в этой группе</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            tagsList && (
                                groupData && (
                                    tagsList.map((tag) =>
                                        <tr>
                                            <td>{tag.name}</td>
                                            <td>
                                                {
                                                    gTagArr.includes(tag.name) ? (
                                                        <Button variant={'danger'} onClick={() => switchTagInGroup(tag.id)}><FileMinusFill/></Button>
                                                    ) : (
                                                        <Button variant={'success'}
                                                                onClick={() => switchTagInGroup(tag.id)}><FilePlusFill/></Button>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    )
                                )
                            )

                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}